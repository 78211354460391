<template>
  <div class="common-table">
    <el-table
      :data="tableData"
      border
      :highlight-current-row="false"
      height="90%"
      v-loading="config.loading"
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column prop="contract_code" label="合同号" width="150" sortable>
        <template slot-scope="scope">
          <router-link :to="{ name: 'zxfwcontractdetail', query: { id: scope.row.id } }">
            <a>{{ scope.row.contract_code }}</a>
          </router-link>
        </template>
      </el-table-column>

      <el-table-column prop="yifang" label="对方公司名称" width="200" sortable> </el-table-column>
      <el-table-column prop="projectPlace" label="施工地点" width="100"> </el-table-column>
      <el-table-column prop="lianxiren" label="联系人" width="100" sortable> </el-table-column>
      <el-table-column prop="contact_phone" label="联系电话" width="80"> </el-table-column>
      <el-table-column prop="jiafang" label="我方公司名称" width="200" sortable> </el-table-column>
      <el-table-column prop="fuzeren" label="负责人" width="100"> </el-table-column>
      <el-table-column prop="is_jiedan" label="结单" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_jiedan === false ? 'primary' : 'success'">{{ scope.row.is_jiedan === false ? '未结单' : '已结单' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="qiankuan_amount" label="欠款金额" width="80"> </el-table-column>
      <el-table-column prop="notreturnmat" label="未返还材料情况" width="200"> </el-table-column>

      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="false">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pager"
      :page-size="pagesize"
      layout="prev, pager, next"
      :total="config.total"
      :current-page.sync="config.page"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'

export default {
  data() {
    return {
      pagesize: 20,
      multipleSelection: []
    }
  },
  props: {
    tableData: Array,
    config: Object,
    usertype: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.usertype.id === 1) return true
        else {
          let added_person_time = utils.stringToDate(row.added_person_time)
          let now = new Date()
          let interval = now.getTime() - added_person_time.getTime()
          interval = interval / 1000 / 60 / 60
          // console.log('addedtime:', added_person_time)
          // console.log('now:', now)
          // console.log('del..........', interval)
          if (interval > 2) return false
          else return true
        }
      }
    }
  },
  methods: {
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },

    handleEdit(row) {
      this.$emit('edit', row)
    },
    handleDelete(row) {
      this.$emit('del', row)
    },
    changePage(page) {
      this.$emit('changePage', page)
    },
    handleSelectionChange(val) {
      // console.log('child Selected rows:', val)
      this.$emit('getSelectedRows', val)
    },

    tableRowClassName({ row, rowIndex }) {
      // console.log(row)
      // console.log(rowIndex)
      if (row.is_statement === true) {
        return 'jiedan-row'
      } else if (rowIndex % 2 === 0) {
        return 'stripe-row'
      }
      return ''
    }
  }
}
</script>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
<style scoped>
.isFinancialConfirmRow {
  color: blue;
}
</style>
